import styled from 'styled-components';
import { HTMLAttributes } from 'react';
import { JobInfo } from '/types/jobTemplateTypes';
import { colors } from '/css';

interface Props extends HTMLAttributes<HTMLDivElement> {
    badgeInfo: JobInfo['badge'];
}

export default function JobCardBadges({ badgeInfo, ...rest }: Props) {
    if (!badgeInfo) return null;

    return (
        <Wrapper {...rest}>
            {badgeInfo.newJob && <div className="badge badge-new">Ny</div>}
            {badgeInfo.enova && (
                <div className="badge badge-enova">
                    <svg
                        style={{ marginRight: 4 }}
                        width="10"
                        height="10"
                        viewBox="0 0 10 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M5.3125 1.87501C3.77734 1.87501 2.47852 2.88086 2.03711 4.26758C2.69336 3.93555 3.43359 3.75001 4.21875 3.75001H5.9375C6.10938 3.75001 6.25 3.89063 6.25 4.06251C6.25 4.23438 6.10938 4.37501 5.9375 4.37501H5.625H4.21875C3.89453 4.37501 3.58008 4.41211 3.27539 4.48047C2.76953 4.59571 2.30078 4.80079 1.88086 5.08008C0.748047 5.83594 0 7.12696 0 8.59376V8.90626C0 9.16602 0.208984 9.37501 0.46875 9.37501C0.728516 9.37501 0.9375 9.16602 0.9375 8.90626V8.59376C0.9375 7.64258 1.3418 6.78711 1.98828 6.18751C2.375 7.66211 3.7168 8.75001 5.3125 8.75001H5.33203C7.91211 8.73633 10 6.19336 10 3.0586C10 2.22657 9.85352 1.43555 9.58789 0.722662C9.53711 0.587896 9.33984 0.593755 9.27148 0.720709C8.9043 1.40821 8.17773 1.87501 7.34375 1.87501H5.3125Z"
                            fill="#111112"
                        />
                    </svg>
                    Enova
                </div>
            )}
            {badgeInfo.brandImage && (
                <div className="badge badge-brand">
                    <img src={badgeInfo.brandImage} alt="Brand logo" />
                </div>
            )}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row-reverse;
    .badge {
        height: 26px;
        padding: 8px;
        border-radius: 13px;
        width: auto;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: var(--font-semibold);
        margin: 0 0 6px 6px;
        border: 2px solid transparent;
        &.badge-new {
            background: ${colors.purple};
            color: #fff;
        }
        &.badge-enova {
            background: ${colors.green25};
        }
        &.badge-brand {
            padding: 2px 6px;
            border-color: ${colors.grey1};
            background: #fff;
            img {
                height: 22px;
                width: auto;
            }
        }
    }
`;
