import ModuleType from '/front/types/frontModuleType';

import heading from './heading';
import image from './image';
import richText from './richText';
import tip from './tip';
import job from './job';
import jobCategory from './jobCategory';
import carousel from './carousel';
import popularJobs from './popularJobs';
import illustrationCards from './illustrationCards';
import jobCategories from './jobCategories';
import jobList from './jobList';
import textAndImage from './textAndImage';
import toggles from './toggles';
import postCodeCheckerV2 from './postCodeCheckerV2';
import emptySpace from './emptySpace';
import goodBetterBest from './goodBetterBest';
import hero from './hero';
import quote from './quote';
import cards from './cards';
import contact from './contact';
import customerStories from './customerStories';
import solarJobEntry from './solarJobEntry';
import solarInstallationDetails from './solarInstallationDetails';
import timeline from './timeline';
import articleIntro from './articleIntro';
import imageGrid from './imageGrid';
import contactCallback from './contact-callback';
import table from './table';
import promoBanner from './promoBanner';
import solarHero from './solarHero';
import moduleTemplate from './moduleTemplate';
import howItWorks from './howItWorks';

interface ModuleMap {
    [_type: string]: ModuleType;
}
const modules: ModuleMap = {
    heading: heading,
    richText: richText,
    image: image,
    tip: tip,
    job: job,
    job_category: jobCategory,
    carousel: carousel,
    popular_jobs: popularJobs,
    illustration_cards: illustrationCards,
    job_categories: jobCategories,
    job_list: jobList,
    text_and_image: textAndImage,
    toggles: toggles,
    post_code_checker_v2: postCodeCheckerV2,
    empty_space: emptySpace,
    good_better_best: goodBetterBest,
    hero: hero,
    quote: quote,
    cards: cards,
    contact: contact,
    customer_stories: customerStories,
    solar_job_entry: solarJobEntry,
    solar_installation_details: solarInstallationDetails,
    timeline: timeline,
    article_intro: articleIntro,
    image_grid: imageGrid,
    contact_callback: contactCallback,
    table: table,
    promo_banner: promoBanner,
    solar_hero: solarHero,
    module_template: moduleTemplate,
    how_it_works: howItWorks,
};

export default modules;
