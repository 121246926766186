import Layout from '/components/Layout';
import HeadMeta from '/components/HeadMeta';
import { config } from '/front';
import usePageState from '/hooks/usePageState';
import Modules from '/front/presentation/Modules';
import PageType from '/front/types/frontPageType';
import Menu from '/front/types/frontMenuType';
import ErrorPage from '/pages/404';

interface Props {
    page: PageType;
    menus: Menu[];
}

export default function CmsPage({ page, menus }: Props) {
    const { pageData, editedPage } = usePageState(page);

    if (pageData === null) return <ErrorPage />;

    const modules = editedPage ? editedPage?.modules : pageData?.modules || [];

    const pageSeo = pageData?.seo || {
        title: '',
        description: '',
        keywords: [],
        image: null,
    };

    const pageSeoImageUrl = config
        .imageUrl(pageSeo.image)
        .width(1200)
        .height(800)
        .url();

    return (
        <Layout menus={menus} isCmsPage={!!pageData}>
            <HeadMeta
                title={pageSeo.title}
                description={pageSeo.description}
                keywords={pageSeo.keywords.join(',')}
                image={pageSeoImageUrl}
                links={[
                    {
                        rel: 'canonical',
                        href: `${process.env.NEXT_PUBLIC_APP_URL}/${pageData?.slug}`,
                    },
                ]}
            />
            <div
                style={{
                    minHeight: '100vh',
                    background:
                        editedPage?.background ||
                        pageData?.background ||
                        '#ffffff',
                }}
            >
                <Modules
                    modules={modules}
                    createdAt={(editedPage || pageData)?.createdAt}
                />
            </div>
        </Layout>
    );
}
