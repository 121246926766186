import { memo, useEffect, useState } from 'react';
import styled from 'styled-components';

import Container from '/components/Container';
import { ui, error, config } from '/front';
import JobCard from '/components/layout/JobCard';
import ScrollObserver from '/components/ScrollObserver';
import { trackSelectItem, trackViewItemList } from '/lib/ga-data-layer';
import useIsJobSupported from '/hooks/useIsJobSupported';

export default memo(function JobListModule({ module, onChange }) {
    const { editMode } = ui();

    const {
        categoryId,
        margin,
        jobs,
        trackingId = 'Generic job list',
    } = module.data;

    useEffect(() => {
        if (!editMode) return;

        (async () => {
            const { data, error: e } = await config.api.page.renderModule(
                module
            );
            if (e) return error(e);
            onChange({ data });
        })();
    }, [categoryId]);

    const onVisible = (revealCount) => {
        if (!editMode && revealCount === 1) {
            trackViewItemList(jobs, trackingId);
        }
    };

    const isJobSupported = useIsJobSupported({ resourceType: 'cart' });

    return (
        <ScrollObserver onVisible={onVisible}>
            <Container className={margin}>
                <JobList>
                    {(jobs || []).map((job, jobIndex) => (
                        <JobCard
                            key={job?.slug + jobIndex}
                            linkProps={{
                                href:
                                    job?.typeKey === 'SOLAR'
                                        ? '/solceller'
                                        : `/bestilling/jobb/${job?.slug}`,
                            }}
                            onClick={() => {
                                if (!editMode) {
                                    trackSelectItem(job, trackingId, jobIndex);
                                }
                            }}
                            openLinksInNewTab={false}
                            job={{
                                typeKey: job?.typeKey,
                                jobTypeVersion: {
                                    name: job?.name,
                                    image: job?.image,
                                    slug: job?.slug,
                                    info: job.info
                                },
                                totals: {
                                    startingPrice: job?.startingPrice,
                                },
                            }}
                            priceOverride={
                                job?.typeKey === 'SOLAR' ? 'Få fastpris' : undefined
                            }
                            supported={isJobSupported(job?.typeKey)}
                        />
                    ))}
                </JobList>
            </Container>
        </ScrollObserver>
    );
});

const JobList = styled.div`
    display: flex;
    flex-wrap: wrap;
`;
