import styled from 'styled-components';
import Link from 'next/link';

import Heading from '/components/ui/Heading';
import Button from '/components/ui/buttons/Button';
import { formatPrice } from '/lib/helpers';
import { JobTemplate } from '/types/jobTemplateTypes';
import PriceType from '/types/priceType';
import Skeleton from '../ui/Skeleton';
import { useHydration } from '/store/store.helpers';
import { effects } from '/css';
import JobCardBadges from './JobCardBadges';

interface JobInfo {
    typeKey: JobTemplate['typeKey'];
    jobTypeVersion: {
        name: JobTemplate['jobTypeVersion']['name'];
        image: JobTemplate['jobTypeVersion']['image'];
        slug: JobTemplate['jobTypeVersion']['slug'];
        [key: string]: any;
    };
    totals: {
        startingPrice: PriceType;
        [key: string]: any;
    };
    [key: string]: any;
}

interface Props {
    job: JobInfo;
    openInNewTab?: boolean;
    linkProps?: { href: string; as?: string };
    onClick?: () => void;
    priceOverride?: string;
    showSkeletonBeforeHydration?: boolean;
    supported?: boolean;
    disabled?: boolean;
}

export default function JobCard({
    job,
    openInNewTab,
    linkProps,
    onClick,
    priceOverride,
    showSkeletonBeforeHydration,
    supported,
    disabled,
}: Props) {
    const hydrated = useHydration();

    /* eslint-disable @next/next/no-img-element */
    return (
        <JobCardWrapper
            className={[
                'job-item',
                linkProps && 'clickable',
                supported === false && 'not-supported',
                disabled && 'disabled',
            ]
                .filter((x) => x)
                .join(' ')}
            id={'job-' + (job.jobTypeId || job.jobTypeVersion?.slug)}
            onDragStart={(e) => e.preventDefault()}
            suppressHydrationWarning
            onClick={onClick}
        >
            <Link
                prefetch={false}
                href={linkProps?.href || '#'}
                target={openInNewTab ? '_blank' : '_self'}
                rel="noopener noreferrer"
                onClick={(e) => {
                    if (disabled) {
                        e.preventDefault();
                    }
                }}
            >
                <div>
                    <div className="icon-title">
                        <div className="icon">
                            <img
                                src={
                                    String(job.jobTypeVersion?.image).includes(
                                        'https://'
                                    )
                                        ? job.jobTypeVersion?.image
                                        : '/images/jobs/' +
                                          job.jobTypeVersion?.image
                                }
                                alt={job.jobTypeVersion?.name + ' - icon'}
                            />
                            <JobCardBadges
                                badgeInfo={job.jobTypeVersion?.info?.badge}
                                className="badges"
                            />
                        </div>
                        <Heading small centered className="job-title" tag="div">
                            {job.name || job.jobTypeVersion.name}
                        </Heading>
                        {supported === false && (
                            <div className="not-supported-info">
                                Ikke tilgjengelig der du bor
                            </div>
                        )}
                    </div>
                    <Button
                        className="price-button"
                        variant={supported === false ? 'tertiary' : 'soft'}
                        size="small"
                        disabled={disabled}
                    >
                        {priceOverride ||
                            `Fra ${formatPrice(
                                job.totals?.startingPrice?.gross || 0
                            )}`}
                    </Button>
                </div>
            </Link>
            {showSkeletonBeforeHydration && !hydrated && (
                <div className="skeleton">
                    <Skeleton className="image" />
                    <Skeleton className="title" />
                    <Skeleton className="desc" />
                    <Skeleton className="button" />
                </div>
            )}
        </JobCardWrapper>
    );
}

const JobCardWrapper = styled.div`
    background: #fff;
    position: relative;
    border-radius: 12px;
    transition: box-shadow 0.2s ease-in-out;
    box-shadow: 0px 2px 6px 0px rgba(31, 26, 34, 0.06);
    box-shadow: 0px 6px 24px 0px rgba(31, 26, 34, 0.06);
    > a,
    > div {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }
    > a div {
        width: 100%;
    }
    &.clickable {
        @media (pointer: fine) {
            &:hover {
                ${effects.shadowHover};
            }
        }
    }
    &.disabled:hover {
        box-shadow: 0px 2px 6px 0px rgba(31, 26, 34, 0.06);
        box-shadow: 0px 6px 24px 0px rgba(31, 26, 34, 0.06);
        cursor: default;
        a {
            cursor: default;
        }
    }
    @media (min-width: 811px) {
        width: calc(25% - 18px); // (3 gaps * 24px) / 4 in row
        margin-right: 24px;
        &:nth-child(4n) {
            margin-right: 0px;
        }
        margin-bottom: 24px;
        padding: 20px 16px 32px 16px;
    }
    @media (min-width: 491px) and (max-width: 810px) {
        width: calc(33.33% - 10.66px); // (2 gaps * 16px) / 3 in row
        margin-right: 16px;
        &:nth-child(3n) {
            margin-right: 0px;
        }
        margin-bottom: 16px;
        padding: 16px 13px 24px 13px;
    }
    @media (max-width: 490px) {
        width: calc(50% - 5.5px); // (1 gap * 11px) / 2 in row
        margin-right: 11px;
        &:nth-child(2n) {
            margin-right: 0px;
        }
        margin-bottom: 11px;
        padding: 12px 8px 18px 8px;
    }
    .label {
        position: absolute;
        top: 12px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        padding: 6px 8px 8px;
        border-radius: 8px;
        white-space: nowrap;
        overflow-wrap: normal;
        word-wrap: normal;
        &:nth-of-type(2) {
            top: 50px;
        }
        &:nth-of-type(3) {
            top: 90px;
        }
    }
    .icon-title {
        width: 100%;
        .icon {
            height: 140px;
            margin-bottom: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            @media (min-width: 491px) and (max-width: 810px) {
                height: 120px;
            }
            @media (max-width: 490px) {
                height: 100px;
                margin-bottom: 8px;
            }
            > img {
                max-height: 100%;
                display: block;
            }
            .badges {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
            }
        }
        .job-title {
            margin-bottom: 12px;
        }
    }
    .not-supported-info {
        color: #75757f;
        margin-bottom: 12px;
    }
    &.not-supported {
        .icon img {
            filter: grayscale(1);
            opacity: 0.35;
        }
    }
    .skeleton {
        position: absolute;
        padding: 20px 16px 20px 16px;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        border-radius: 12px;
        .image {
            height: 140px;
            width: 80%;
            margin-bottom: 20px;
        }
        .title {
            height: 30px;
            width: 90%;
            margin-bottom: 12px;
        }
        .desc {
            height: 24px;
            width: 70%;
            margin-bottom: 12px;
        }
        .button {
            height: 36px;
            width: 100px;
        }
    }
`;
