import PostCodeCheckerModule from './PostCodeCheckerModule';
import PostCodeCheckerModuleSettings from './PostCodeCheckerModuleSettings';
import { PostCodeCheckerModuleType } from './types';
import { ContainerSizes } from '/components/Container';

const moduleData: PostCodeCheckerModuleType = {
    _key: '',
    _type: 'post_code_checker_v2',
    title: 'Post code checker',
    description: 'Post code checker',
    image: '/modules/postCodeChecker.svg',
    moduleComponent: PostCodeCheckerModule,
    settingsComponent: PostCodeCheckerModuleSettings,
    data: {
        margin: 'mb-60',
        theme: 'simple',
        containerWidth: ContainerSizes.LARGE,
        image: null,
    },
};

export default moduleData;
